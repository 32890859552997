import React, { useState } from "react";
import { Container, Row, Col, Dropdown } from "react-bootstrap";
import "./FilterBottom.scss";
import Slider from "react-slick";
import { AnimationOnScroll } from "react-animation-on-scroll";

const FilterBlock = () => {
  var settings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 8,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1801,
        settings: {
          dots: false,
          arrows: false,
          slidesToShow: 8,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1799,
        settings: {
          dots: false,
          arrows: false,
          slidesToShow: 6.2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1401,
        settings: {
          dots: false,
          arrows: false,
          slidesToShow: 8,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1399,
        settings: {
          dots: false,
          arrows: false,
          slidesToShow: 6,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          dots: false,
          arrows: false,
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 766,
        settings: {
          dots: false,
          arrows: false,
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 370,
        settings: {
          dots: false,
          arrows: false,
          slidesToShow: 1.5,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="filter-bottom">
      <Container>
        <Row>
          <Col md={12}>
            <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp">
              <div className="filter-boxes">

                <div className="filter-wrapper">

                  <Slider {...settings}>

                    <div className="filter-box">
                      <div className="name">
                        <h3>Properties with</h3>
                        <h4>Waterview</h4>
                      </div>
                      <div className="number">
                        17
                        <i className="icon-close"></i>
                      </div>
                    </div>

                    <div className="filter-box">
                      <div className="name">
                        <h3>Properties with</h3>
                        <h4>Balcony</h4>
                      </div>
                      <div className="number">
                        38
                        <i className="icon-close"></i>
                      </div>
                    </div>

                    <div className="filter-box">
                      <div className="name">
                        <h3>Properties with</h3>
                        <h4>View of Landmark</h4>
                      </div>
                      <div className="number">
                        51 <i className="icon-close"></i>
                      </div>
                    </div>

                    <div className="filter-box rent">
                      <div className="name ">
                        <h3>Properties</h3>
                        <h4>Rented</h4>
                      </div>
                      <div className="number">
                        84
                        <i className="icon-close"></i>
                      </div>
                    </div>

                    <div className="filter-box">
                      <div className="name">
                        <h3>Properties</h3>
                        <h4>Upgraded</h4>
                      </div>
                      <div className="number">17
                        <i className="icon-close"></i>

                      </div>
                    </div>

                    <div className="filter-box">
                      <div className="name">
                        <h3>Properties</h3>
                        <h4>Vacant on Transfer</h4>
                      </div>
                      <div className="number">33
                        <i className="icon-close"></i>

                      </div>
                    </div>

                    <div className="filter-box">
                      <div className="name">
                        <h3>Properties</h3>
                        <h4>Close to Park</h4>
                      </div>
                      <div className="number">12
                        <i className="icon-close"></i>

                      </div>
                    </div>

                    <div className="filter-box">
                      <div className="name">
                        <h3>Property for sale in</h3>
                        <h4>Green Community</h4>
                      </div>
                      <div className="number">12
                        <i className="icon-close"></i>

                      </div>
                    </div>

                    <div className="text-box">+4 more</div>

                  </Slider>
                </div>
              </div>
            </AnimationOnScroll>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default FilterBlock;
