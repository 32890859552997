import React, { useEffect } from "react"
import { useState } from "react"
import { Container, Row, Col } from "react-bootstrap";
import propertyImg from "../../images/search/sales03.png";
import TabImg from "../../images/search/result-tab.png";
import DeskImg from "../../images/search/result-1400.png";

import Img01 from "../../images/search/residence01.png";
import Img02 from "../../images/search/residence02.png";
import Img03 from "../../images/search/residence03.png";
import { AnimationOnScroll } from "react-animation-on-scroll";

import "./AboutResult.scss";
import { useWindowSize } from "../../hooks/window-size";
import {Offlinedate} from "../common/site/functions"
import parse from "html-react-parser"
import GetGGFXImage from "../common/site/get-ggfx-image";
import { Link } from "gatsby";
import useDeviceMedia from "../../hooks/useDeviceMedia"
import usePagination from "../../hooks/usePagination"
import Pagination from "../Pagination/PaginationCus"
import { CurrConvertPrice } from '../currency-converter/CurrConvertPrice'

const aboutProperty = {
  image: propertyImg,
  imageTab: TabImg,
  DeskImg: DeskImg,
  heading: "Peninsula Five,",
  detail: " Business Bay by Select Group",
  location: "Business Bay",
  developer: "Select Group",
  developmentType: "Waterfront Community",
  CompletionDate: "December 2024",
};

const aboutResult = {
  image: Img01,
  imageTab: Img02,
  DeskImg: Img03,
  heading: "Six Senses Residences",
  detail: "  The Palm, Dubai",
  location: " Palm Jumeirah",
  developer: "Select Group",
  developmentType: "Beachfront Community",
  CompletionDate: "TBC",
};
function limit (string = '', limit = 0) {  
  return string.substring(0, limit)
}
const AboutResult = (props) => {
  const [windowWidth] = useWindowSize();
 
  const { isMobile, isTablet } = useDeviceMedia()
  const [selectedProperties, setSelectedProperties] = useState(props.properties)
  useEffect(() => {
    setCurrentPage(1)
    setSelectedProperties(props.properties)
  }, [selectedProperties, props.properties])

   // for pagination
   const itemsPerPage = isMobile ? 12 : isTablet ? 12 : 12
   const { currentItems, currentPage, setCurrentPage } = usePagination({
     items: selectedProperties,
     itemsPerPage,
   })
   // for pagination
  
  return (
    <div className="about-result offplan-search">
      <Container>
        <Row>
          <Col md={12}>
            <div className="sale-wrapper">
          {currentItems.map((item, i) => {
            //var offline_desc = limit(item.description, 300)  
            let processedImages = JSON.stringify({});
            if (item?.imagetransforms?.image_Transforms) {
             processedImages = item.imagetransforms.image_Transforms;
            }
            return (
            <div className="property-card img-zoom" key={item.id}>
              <div className="property-wrap">
                <div className="property-img">
                <Link state={{completionval:props.completionval,developerval:props.developerval,propertyval:props.propertyval,minpriceVal:props.minpriceVal,maxpriceVal:props.maxpriceVal,minbedVal:props.minbedVal,maxbedVal:props.maxbedVal,bedVal:props.bedVal,areasArr:props.areasArr}} to={`/off-plan-properties-in-dubai/${item.URL}/`}>
                  <picture>
                    <GetGGFXImage imagename={"offplan.images.image"} imagesources={item.images?item.images[0]:""} fallbackalt={item.name} imagetransformresult={processedImages} id={item.id}/>                  
                  </picture>
                </Link>
                  
                </div>
                <div className="content-wrap">
                  <Link state={{completionval:props.completionval,developerval:props.developerval,propertyval:props.propertyval,minpriceVal:props.minpriceVal,maxpriceVal:props.maxpriceVal,minbedVal:props.minbedVal,maxbedVal:props.maxbedVal,bedVal:props.bedVal,areasArr:props.areasArr}} to={`/off-plan-properties-in-dubai/${item.URL}/`}><h2>{item.name}</h2></Link>
                  <ul>
                    <li>
                      <label>Location:</label>
                      <span className="detail-wrap">
                        {item.location}
                      </span>
                    </li>
                    <li>
                      <label>Developer:</label>
                      <span className="detail-wrap">
                        {item.developer}
                      </span>
                    </li>

                    <li>
                      <label>Development Type:</label>
                      <span className="detail-wrap">
                        {item.developement_type}
                      </span>
                    </li>

                    <li>
                      <label>Handover:</label>
                      <span className="detail-wrap">
                        {Offlinedate(item.completion_date)}
                      </span>
                    </li>
                    <li>
                      <label>Prices From:</label>
                      <span className="detail-wrap">
                        {CurrConvertPrice(item.price.toLocaleString())}
                      </span>
                    </li>
                  </ul>
                  { /*
                  <p>
                    {parse(item.description.replace(/(<([^>]+)>)/gi, ""))}
                  </p>
                  <div className="description-link">
                    <Link state={{completionval:props.completionval,developerval:props.developerval,propertyval:props.propertyval,minpriceVal:props.minpriceVal,maxpriceVal:props.maxpriceVal,minbedVal:props.minbedVal,maxbedVal:props.maxbedVal,bedVal:props.bedVal,areasArr:props.areasArr}} to={`/off-plan-properties-in-dubai/${item.URL}/`} className="read-more">
                      view details
                      <i className="right-more"></i>
                    </Link>
                  </div>
                */ }
                </div>
              </div>
              </div>
            )
          })}
          </div>
          <Pagination
            currentPage={currentPage}
            currentItems={currentItems}
            itemsPerPage={itemsPerPage}
            totalItems={selectedProperties.length}
            setCurrentPage={setCurrentPage}
          />
          </Col>
        </Row>
      </Container>

    </div>
  );
};

export default AboutResult;
